document.addEventListener('DOMContentLoaded', function () {
    const learnMoreButton = document.querySelector('a[href="#about"]');
    const offset = 100; // Adjust this value to match your header height or desired offset

    if (learnMoreButton) {
        learnMoreButton.addEventListener('click', function (e) {
            e.preventDefault();
            const target = document.querySelector('#about');

            if (target) {
                const targetPosition = target.getBoundingClientRect().top + window.scrollY - offset;
                window.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth'
                });
            }
        });
    }
});
